<template>
    <Users></Users>
</template>

<script>
    import Users from "@/views/user_settings/users/components/users";

    export default {
        name: "index",
        components: {Users}
    }
</script>

<style scoped>

</style>